import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { useSessionStorage } from "react-use";

const Page = styled.div`
  padding: 30px;

  & .info-text {
    & p {
      margin: 0;

      &:first-of-type {
        margin: 0 0 1em 0;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Grid = styled.ol`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  grid-auto-rows: 54px;

  padding: 0;
  margin: 0;

  & li {
    & a {
      font-family: "Recursive", monospace, "Helvetica Neue", "Lucida Grande",
        sans-serif;
      font-variation-settings: "MONO" 1, "CASL" 0, "wght" 400, "slnt" 0,
        "CRSV" 0;
    }

    &.span-2 {
      grid-column: span 2;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: 30px;

    & li {
      grid-column: span 2;
    }
  }
`;

const Contact = ({ data }) => {
  return (
    <Page>
      <Grid>
        <li>
          <Link to={`/`}>Arc Editions</Link>
        </li>
        <li></li>
        <li>
          <Link to={`/contact/`}>Contact</Link>
        </li>
        <li>
          <div
            className="info-text"
            dangerouslySetInnerHTML={{
              __html: data.prismicContact.data.text.html,
            }}
          />
        </li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </Grid>
    </Page>
  );
};

export default withPreview(Contact);

export const query = graphql`
  {
    prismicContact {
      data {
        text {
          html
        }
      }
    }
  }
`;
