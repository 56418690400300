import * as React from "react";
import { withUnpublishedPreview } from "gatsby-source-prismic";

import Index from "./index";
import Contact from "./contact";
import Artist from "../templates/artist";
import Project from "../templates/project";

const NotFoundPage = () => (
  <>
    <h1>Page not found!</h1>
  </>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    artist: Artist,
    homepage: Index,
    project: Project,
    contact: Contact,
  },
});
