import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";

const Page = styled.div`
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }

  & .back-button,
  & .bibliography-button {
    font-variation-settings: "MONO" 1, "CASL" 0, "wght" 400, "slnt" 0, "CRSV" 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 54px;

  grid-gap: 30px;
  grid-auto-flow: dense;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: unset;
  }

  & .back-button,
  & .bibliography-button,
  & .artist-biography,
  & .artist-image-container {
    height: fit-content;
  }

  // & a {
  //   display: block;
  // }

  &.projects-container {
    & .artist-biography {
      & p:first-of-type {
        margin-top: 0;
      }
    }

    & .artist-image-container {
      & .project-title {
        margin: 30px 0;
      }
    }
  }

  &.grid-layout-h {
    & .artist-image-1 {
      grid-column: 1;
      grid-row: 2;
    }

    & .artist-image-2 {
      grid-column: 1;
      grid-row: 13;
    }

    & .artist-image-3 {
      grid-column: 3;
      grid-row: 7;
    }

    & .artist-biography {
      grid-column: 2;
      grid-row: 3;
    }
  }

  &.grid-layout-k {
    & .artist-image-1 {
      grid-column: 1;
      grid-row: 2;
    }

    & .artist-image-2 {
      grid-column: 2;
      grid-row: 10;
    }

    & .artist-image-3 {
      grid-column: 3;
      grid-row: 6;
    }

    & .artist-biography {
      grid-column: 2;
      grid-row: 4;
    }
  }

  &.grid-layout-s {
    & .artist-image-1 {
      grid-column: 2;
      grid-row: 2;
    }

    & .artist-image-2 {
      grid-column: 2;
      grid-row: 12;
    }

    & .artist-image-3 {
      grid-column: 3;
      grid-row: 7;
    }

    & .artist-image-4 {
      grid-column: 3;
      grid-row: 18;
    }

    & .artist-biography {
      grid-column: 1;
      grid-row: 7;
    }
  }

  &.grid-layout-r {
    & .artist-image-1 {
      grid-column: 1;
      grid-row: 2;
    }

    & .artist-image-2 {
      grid-column: 2;
      grid-row: 6;
    }

    & .artist-image-3 {
      grid-column: 1;
      grid-row: 11;
    }

    & .artist-image-4 {
      grid-column: 2;
      grid-row: 16;
    }

    & .artist-biography {
      grid-column: 3;
      grid-row: 6;
    }
  }

  &.grid-layout-v {
    & .artist-image-1 {
      grid-column: 2;
      grid-row: 2;
    }

    & .artist-image-2 {
      grid-column: 2;
      grid-row: 13;
    }

    & .artist-image-3 {
      grid-column: 3;
      grid-row: 7;
    }

    & .artist-biography {
      grid-column: 1;
      grid-row: 8;
    }
  }

  @media (max-width: 768px) {
    &.grid-layout-h,
    &.grid-layout-k,
    &.grid-layout-s,
    &.grid-layout-r,
    &.grid-layout-v {
      & .artist-biography {
        grid-column: 1 / 3;
        order: 1;
      }

      & .artist-biography,
      & .artist-image-container,
      & .artist-image-1,
      & .artist-image-2,
      & .artist-image-3,
      & .artist-image-4 {
        order: 2;

        grid-column: 1 / 3;
        grid-row: unset;
      }
    }

    &.grid-layout-h,
    &.grid-layout-r,
    &.grid-layout-v {
      & .artist-biography {
        & p:first-of-type {
          text-indent: calc(50% + 10px);
        }
      }
    }
  }
`;

const Artist = ({ data }) => {
  const projects = data.prismicArtist.data.projects.map((project, index) => (
    <div
      key={`single_project_${index}`}
      className={`artist-image-${index + 1} artist-image-container`}
    >
      <Link to={project.project.document.url}>
        {project.project.document.data.thumbnail.fluid !== null && (
          <img
            srcSet={project.project.document.data.thumbnail.fluid.srcSetWebp}
            src={project.project.document.data.thumbnail.fluid.srcWebp}
            alt={project.project.document.data.thumbnail.alt}
            loading={`lazy`}
          />
        )}

        <p className="project-title">
          {project.project.document.data.title.text}
        </p>
      </Link>
    </div>
  ));

  return (
    <>
      <Helmet title={`Arc Editions - ${data.prismicArtist.data.name.text}`} />
      <Page>
        {/* <Grid>
        <li>
          <Link to={`/`}>Arc Editions</Link>
        </li>
        <li>
          <button>Bibliography</button>
        </li>
      </Grid> */}

        <Grid
          className={`projects-container grid-layout-${data.prismicArtist.data.name.text
            .charAt(0)
            .toLowerCase()}`}
        >
          <div className="back-button">
            <Link to={`/`}>Arc Editions</Link>
          </div>
          {/* <div className="bibliography-button">
            <button>Bibliography</button>
          </div> */}

          <div
            className="artist-biography"
            dangerouslySetInnerHTML={{
              __html: data.prismicArtist.data.biography.html,
            }}
          />

          {projects}
        </Grid>
      </Page>
    </>
  );
};

export default withPreview(Artist);

export const query = graphql`
  query Artist($uid: String!) {
    prismicArtist(uid: { eq: $uid }) {
      data {
        name {
          html
          text
        }
        biography {
          html
        }
        bibliography {
          html
        }
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    text
                  }
                  thumbnail {
                    fluid {
                      srcWebp
                      srcSetWebp
                    }
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
